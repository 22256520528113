.row{
    justify-content: center;
    background:  rgb(248, 227, 227) 
}
.card{
    background: #660131 !important;
    box-sizing: border-box;
    box-shadow: 2px 5px 12px 1px grey;
}
.contactusHead {
    border: none;
    background: rgb(248, 227, 227) !important;
    align-items: center;
    border: 0;
    z-index: -1;
    padding: 32px 0px 15px 0px;
    font-weight: 700;
  }
.userLogin {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 40px;
    color: white;
}
.form_input {
    padding: 8px 0px 8px 40px;
    box-sizing: border-box;
    border: 1px solid rgb(206, 201, 201);
    width: 100%;
    outline: none;
    font-size: 14px;
  }
  .form_input::placeholder {
    color: rgb(187, 182, 182);
  }
  .text {
    position: absolute;
    margin: 5px 10px;
    color: rgb(151, 147, 147);
  }
  .login_btn{
    font-weight: 600 !important;
    padding: 4px 70px 4px 70px !important;
    align-items: center;
    text-align: center;
    border: none !important;
    background: rgb(109, 108, 108) !important;
    box-sizing: border-box;
    box-shadow: 4px 7px 7px -1px black
  }
  .login_section{
    text-align: center;
  }
  .signup_link{
    font-size: 15px;
    float: right;
    font-weight: 600;
    cursor: pointer;
    color: goldenrod;
    text-decoration: none;
  }

  .f_password{
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    color: goldenrod;
    text-decoration: none;
  }
  .signup_link:hover,.f_password:hover{
    color: grey;
  }