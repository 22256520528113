.contactusHead {
    background: rgb(248, 227, 227) !important;
    align-items: center;
    border: 0;
    z-index: -1;
    padding: 32px 0px 15px 0px;
    font-weight: 700;
  }
  .cardBody {
    margin: 5% 0px 10% 0px;
    box-sizing: border-box;
    filter: drop-shadow(0 0 7px rgba(90, 9, 43, 0.25));
  }
  