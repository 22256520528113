.cardImg{
    height: 80%;
    width: 100%;
}
.registerCard{
    align-items: center !important;
    text-align: center !important;
    background: rgb(247, 174, 185) !important;
}
.complaintCard{
    text-align: center;
    background: lightskyblue !important;
}
.contactCard{
    text-align: center;
    background: lightgreen!important;

}
.cardbutton{
    padding: 6px 30px !important;
    margin-top: 15px;
    background: rgb(16, 16, 87) !important;
}
.cardlinks{
    text-decoration: none;
}
.cardBody {
    margin: 5% 0px 5% 0px;
    box-sizing: border-box;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.25));
  }
  .registerbtn{
    text-decoration: none;
    font-weight: 600;
    color: black;
    padding: 2px 15px ;
  }