  .messageHeading{
    color: #9b004b;
  }
  .contactusHead {
    background: rgb(248, 227, 227) !important;
    align-items: center;
    border: 0;
    z-index: -1;
    padding: 32px 0px 15px 0px;
    font-weight: 700;
  }
  .Card_input_field {
    padding-bottom: 15%;
    font-size: 14px;
    border: 1px solid rgb(206, 201, 201);
  }
  .Card_input_field::placeholder {
    color: rgb(185, 180, 180);
  }
  .icon {
    padding: 10px;
    color: white;
    background: #9b004b;
  }
  .form_input {
    padding: 8px 0px 8px 40px;
    box-sizing: border-box;
    border: 1px solid rgb(206, 201, 201);
    width: 100%;
    outline: none;
    font-size: 14px;
  }
  .form_input::placeholder {
    color: rgb(187, 182, 182);
  }
  .submitbtn{
    font-weight: 600;
    border: 1px solid #660131 ;
    background: #660131 !important;
  }
  .submitbtn:hover{
    font-weight: 600;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    border: 1px solid #660131 !important;
    color: #660131  !important;
    background: none !important;
  }
