.nav{
    background: #660131;
}
.navbar {
  list-style-type: none;
  margin: 10px;
}
.navlinks {
  margin-right: 30px;
  text-decoration: none;
  color: white !important;
  font-size: 15px;
  font-weight: 600;
}
.navlinks:hover {
  color: #f1bc0b !important;
}
.buttons {
  justify-content: space-between;
  float: right;
}
.complaintbtn{
  margin-top: 30px;
  font-weight: 600 !important;
  background-color: goldenrod !important;
  border: 1px goldenrod !important;
  padding: 5px 25px !important;
  float: right;
}

