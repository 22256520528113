.faqsHead {
  background: rgb(248, 227, 227) !important;
  align-items: center;
  border: 0;
  z-index: -1;
  padding: 32px 0px 15px 0px;
  font-weight: 700;
  }
  .cardBody {
    margin: 5% 0px 10% 0px ;
  box-sizing: border-box;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.25));
}
.items:active,items:hover,items:focus{
  background: rgb(175, 2, 2) !important;
}

