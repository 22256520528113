body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
overflow: auto;
padding-right:0px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.accordion-button:not(.collapsed){
  background-color: #660131 !important;
  color: white !important;
}
.accordion-button:focus{
  z-index: 3;
    border-color: #660131 !important;
    outline: 0;
    box-shadow:none !important;
}
